import { app } from '@/main'

export function empty (validate) {
  return (
    (
      typeof validate === 'undefined' ||
      typeof validate === 'function' ||
      validate === null
    ) ||
    (
      typeof validate === 'string' &&
      (
        validate.trim().length === 0 // ||
        // validate.trim().toString() === '0'
      )
    ) ||
    (
      typeof validate === 'object' &&
      (
        validate.constructor === Object ||
        validate.constructor === Array
      ) &&
      (
        Object.getOwnPropertyNames(validate).length === 0 ||
        Object.getOwnPropertyNames(validate).indexOf('length') === 0
      )
    )
  ) ||
  false
}

export function formatDate (date) {
  const d = new Date(date)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
}

export function formatPreviousDate (date) {
  const previousDate = new Date(date).getTime() - (new Date(date).getTimezoneOffset() * 240000)

  const d = new Date(previousDate)
  let month = `${d.getMonth() + 1}`
  let day = `${d.getDate()}`
  const year = d.getFullYear()

  if (month.length < 2) month = `0${month}`
  if (day.length < 2) day = `0${day}`

  return [year, month, day].join('-')
}

export function relativeTimeConvert (date) {
  date.replace(' ', 'T')
  return app.$dayjs().to(app.$dayjs(date))
}

export function batteryCalculate (value) {
  return ((100 / 0.60) * (parseFloat(value) - 3.60)).toFixed(2)
}

export function getAlertMessage (value) {
  const alerts = {
    M: app.$t('Messages.MedicEmergency'),
    P: app.$t('Messages.Police'),
    B: app.$t('Messages.Firefighters'),
    C: app.$t('Messages.Contact'),
    S: app.$t('Messages.NoAlert')
  }

  return alerts[value] || ''
}

export function formatDateLanguages (val, opc = '') {
  const fecha = new Date(val)
  let options = { year: 'numeric', month: 'long', day: 'numeric' }
  if (opc === 'hour') {
    options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
  } else if (opc === 'month') {
    options = { year: 'numeric', month: 'long' }
  }
  const operator = new Date(fecha)
  return new Date(operator).toLocaleString('es-ES', options)
}

export function decimales (addr, posicion) {
  posicion = addr.length - posicion
  return addr.slice(0, posicion) + '.' + (addr).slice(posicion)
}

export function timeZoneDate (date, type = 'default') {
  const resolvedOptions = Intl.DateTimeFormat().resolvedOptions()
  let options = {}
  let result = ''
  date = new Date(date).getTime() - (new Date(date).getTimezoneOffset() * 60000)
  const typeDate = {
    dayJs: () => {
      options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }
      result = new Date(date).toLocaleString(`${app.$i18n.locale}-${app.$i18n.locale.toUpperCase()}`, options, { timeZone: resolvedOptions.timeZone }).replace(/\//g, '-').replace(',', '')
      const fechaFormat = result.split('-')
      return `${fechaFormat[2].split(' ')[0]}-${fechaFormat[1]}-${fechaFormat[0]} ${fechaFormat[2].split(' ')[1]}`
    },
    hour: () => {
      options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' }
      return new Date(date).toLocaleString(`${app.$i18n.locale}-${app.$i18n.locale.toUpperCase()}`, options, { timeZone: resolvedOptions.timeZone })
    },
    mounth: () => {
      options = { year: 'numeric', month: 'long' }
      return new Date(date).toLocaleString(`${app.$i18n.locale}-${app.$i18n.locale.toUpperCase()}`, options, { timeZone: resolvedOptions.timeZone })
    },
    default: () => {
      options = { year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleString(`${app.$i18n.locale}-${app.$i18n.locale.toUpperCase()}`, options, { timeZone: resolvedOptions.timeZone })
    }
  }
  // eslint-disable-next-line dot-notation
  return (typeDate[type] || typeDate['default'])()
}
