import Vue from 'vue'
import I18n from 'vue-i18n'
import { getSessionStorage } from '@/libs/session/index'

const messages = {}

Vue.use(I18n)

export default new I18n({
  locale: getSessionStorage('language') || process.env.VUE_APP_LANG_DEFAULT, // set default locale
  messages
})
