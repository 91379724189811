import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import loading from './loading'
import session from './session'
import lang from './lang'
import sessionExpired from './sessionExpired'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    loading,
    session,
    lang,
    sessionExpired
  },
  strict: process.env.DEV !== 'production'
})
