
import axios from 'axios'
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('1d', null, null, null, true, 'Strict')

export function setSessionStorage (key, value) {
  const data = Vue.$cookies.get('session') || {}
  data[key] = value
  Vue.$cookies.set('session', data)
}

export function getSessionStorage (key) {
  const data = Vue.$cookies.get('session') || {}
  return data[key]
}

export function getToken () {
  return getSessionStorage('token')
}

export function getUser () {
  return {
    email: atob(getSessionStorage('email')),
    password: getSessionStorage('pass')
  }
}

export function parseJwt (token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
    const temp = `00${c.charCodeAt(0).toString(16)}`
    return `%${(temp).slice(-2)}`
  }).join(''))
  return JSON.parse(jsonPayload)
}

export function setToken (token) {
  const jwt = parseJwt(token)
  setSessionStorage('token', token)
  setSessionStorage('expirationDate', jwt.u.expirationDate)
  // setSessionStorage('jwt', token)
}

export function validateToken () {
  const token = getToken()
  if (token !== null) {
    const expirationDate = new Date(getSessionStorage('expirationDate'))
    const today = new Date()
    return (!expirationDate < today)
  } else return false
}

export async function generateToken () {
  return new Promise(resolve => {
    const user = getUser()
    axios.post(`${process.env.VUE_APP_API_URL || ''}login`, user).then(res => {
      if (res.status === 200) {
        setToken(res.data.data)
        resolve(getToken())
      }
    })
  })
}

export function getUserInfo () {
  return Vue.$cookies.get('session')
}

export function hashString (phase) {
  return window.btoa(phase).split('').reverse().join('')
}

export function unHashString (phase) {
  const reversed = phase.split('').reverse().join('')
  return window.atob(reversed)
}

export function logout () {
  localStorage.setItem('avatar', '')
  Vue.$cookies.remove('session')

  // This is just for demo Purpose. If user clicks on logout -> redirect
  location.reload()
}
