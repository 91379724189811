export default {
  namespaced: true,
  state: {
    loading: false
  },
  getters: {
    current: state => {
      const { loading } = state
      return loading
    }
  },
  mutations: {
    CLOSE (state) {
      state.loading = false
      const appLoading = document.getElementById('loading-bg')
      appLoading.style.display = 'none'
    },
    SHOW (state) {
      state.loading = true
      const appLoading = document.getElementById('loading-bg')
      appLoading.style.display = 'block'
    }
  },
  actions: {}
}
