import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import {
  BButton
} from 'bootstrap-vue'

Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(vSelect)
Vue.component(BButton)
