import { getUserInfo } from '@/libs/session/index'
import { empty } from '@/libs/tools/helpers'
import VueCookies from 'vue-cookies'
import Vue from 'vue'
Vue.use(VueCookies)
Vue.$cookies.config('1d', null, null, null, true, 'Strict')

const userDefaults = {
  uuid: 0, // From Auth
  displayName: 'General User', // From Auth
  userRole: 'TP',
  token: '',
  avatar: '',
  division: {
    uuid: '',
    name: ''
  }
}

export default {
  namespaced: true,
  state: {
    AppActiveUser: function () {
      const userInfoLocalStorage = getUserInfo()
      const userInfo = {}
      if (!empty(userInfoLocalStorage)) {
        // Update property in user
        Object.keys(userDefaults).forEach((key) => {
          // If property is defined in localStorage => Use that
          userInfo[key] = userInfoLocalStorage[key] ? userInfoLocalStorage[key] : userDefaults[key]
        })

        // Include properties from localStorage
        Object.keys(userInfoLocalStorage).forEach((key) => {
          if (userInfo[key] === undefined && userInfoLocalStorage[key] !== null) userInfo[key] = userInfoLocalStorage[key]
        })
      }
      return userInfo
    }

  },
  getters: {
    current: state => {
      const { AppActiveUser } = state
      return AppActiveUser
    }
  },
  mutations: {
    UPDATE_USER_INFO (state, payload) {
      const userInfo = Vue.$cookies.get('session') || state.AppActiveUser
      for (const property of Object.keys(payload)) {
        if (payload[property] !== null) {
          // If some of user property is null - user default property defined in state.AppActiveUser
          state.AppActiveUser[property] = payload[property]

          // Update key in localStorage
          userInfo[property] = payload[property]
          // Store data in sessionStorage
        }
      }
      Vue.$cookies.set('session', userInfo)
    }
  },
  actions: {}
}
