import { app } from '@/main'
import { getSessionStorage, setSessionStorage } from '@/libs/session/index'

export default {
  namespaced: true,
  state: {
    language: getSessionStorage('language') || process.env.VUE_APP_LANG_DEFAULT || 'es'
  },
  getters: {
    current: state => {
      const { language } = state
      return language
    }
  },
  mutations: {
    SET_LANG (state, payload) {
      state.language = payload
      app.$i18n.locale = payload
      app.$dayjs.locale(payload)
      app.$validator.localize(payload)
      setSessionStorage('language', payload)
    }
  },
  actions: {}
}
