import Vue from 'vue'
import { ToastPlugin, ModalPlugin, VBTooltip } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// i18n
import VueI18n from 'vue-i18n'

// i18n
import i18n from './libs/i18n/index'

// axios
import axios from 'axios'

// cookies
import VueCookies from 'vue-cookies'

// SweetAlert
import VueSweetalert2 from 'vue-sweetalert2'
import 'sweetalert2/dist/sweetalert2.min.css'

import vSelect from 'vue-select'

// days
import * as dayjs from 'dayjs'
import * as relativeTimePlugin from 'dayjs/plugin/relativeTime'

import * as Validator from 'vee-validate'

import VueMask from 'v-mask'

import VueClipboard from 'vue-clipboard2'

import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
import { getToken } from '@/libs/session/index'

Vue.use(new VueSocketIO({
  debug: true,
  connection: SocketIO.connect(process.env.VUE_APP_SOCKET_URL || '', {
    extraHeaders: {
      token: getToken()
    },
    autoConnect: true
  }),
  vuex: {
    store
  }
})
)

Vue.use(VueClipboard)

Validator.localize('es')
Vue.prototype.$validator = Validator

Vue.directive('b-tooltip', VBTooltip)

// time days
require('dayjs/locale/es')
require('dayjs/locale/en')

Vue.use(VueMask)

Vue.use(VueCookies)

Vue.use(VueSweetalert2)

Vue.component('v-select', vSelect)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.$cookies.config('1d', null, null, null, true, 'Strict')

Vue.config.productionTip = false

Vue.use(VueI18n)

dayjs.locale(i18n.locale)
dayjs.extend(relativeTimePlugin)

Vue.prototype.$dayjs = dayjs

export const messages = async () => {
  const es = await axios({ url: `${process.env.VUE_APP_LANG_URL}/es.json`, method: 'GET' }).then(res => res.data).catch(err => console.log(err))
  const en = await axios({ url: `${process.env.VUE_APP_LANG_URL}/en.json`, method: 'GET' }).then(res => res.data).catch(err => console.log(err))

  i18n.setLocaleMessage('en', en)
  i18n.setLocaleMessage('es', es)
  return {
    en,
    es
  }
}

export const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
