import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { empty } from '@/libs/tools/helpers'
import { messages } from '@/main'
import { logout } from '@/libs/session'

Vue.use(VueRouter)

const appActiveUser = () => store.state.session.AppActiveUser()

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes: [
    /* {
      path: '/',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/login',
      name: 'login-session',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full'
      }
    }, */
    /* {
      path: '/',
      name: 'login1',
      component: () => import('@/views/Login1.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/login1',
      name: 'login1-session',
      component: () => import('@/views/Login1.vue'),
      meta: {
        layout: 'full'
      }
    } */
    {
      path: '/',
      name: 'login2',
      component: () => import('@/views/Login2.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/login',
      name: 'login-session',
      component: () => import('@/views/Login2.vue'),
      meta: {
        layout: 'full'
      }
    },
    {
      path: '/login2',
      name: 'login1-session',
      component: () => import('@/views/Login2.vue'),
      meta: {
        layout: 'full'
      }
    }
  ]
})

const menu = [
  {
    path: '/menu',
    name: 'menu',
    component: () => import('@/views/Menu.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      pageTitle: 'Menú'
    }
  },
  {
    path: '/update-one-time-password',
    name: 'update-one-time-password',
    component: () => import('@/views/Users/UpdatePasswordView.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      pageTitle: 'Actualizar contraseña'
    }
  },
  {
    path: '/services/sinamed-sync',
    permissions: ['A'],
    component: () => import('@/views/Services/SinamedSync.vue')
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/Users/List.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        { text: 'Listado', to: '/users/list', active: true }
      ],
      pageTitle: 'Usuarios'
    }
  },
  {
    path: '/zones',
    name: 'zones',
    component: () => import('@/views/Zones/List.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        { text: 'Listado', to: '/zones', active: true }
      ],
      pageTitle: 'Zonas'
    }
  },
  {
    path: '/zones/create',
    name: 'zones-create',
    component: () => import('@/views/Zones/Create.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        { text: 'Listado', to: '/zones' },
        { text: 'Registrar Zona', to: '/zones/create', active: true }
      ],
      pageTitle: 'Zonas'
    }
  },
  {
    path: '/zones/:uuid/edit',
    name: 'zones-create',
    component: () => import('@/views/Zones/Edit.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        { text: 'Listado', to: '/zones' },
        { text: 'Editar Zona', to: '/zones/:uuid/edit', active: true }
      ],
      pageTitle: 'Editar zona'
    }
  },
  {
    path: '/users/add',
    name: 'users-add',
    component: () => import('@/views/Users/Add.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        { text: 'Listado', to: '/users' },
        { text: 'Agregar', to: '/users/add', active: true }
      ],
      pageTitle: 'Nuevo Usuario'
    }
  },
  {
    path: '/users/update/:uuid',
    name: 'users-update',
    component: () => import('@/views/Users/Update.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        { text: 'Listado', to: '/users' },
        { text: 'Editar', to: '/users/update', active: true }
      ],
      pageTitle: 'Usuarios'
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Users/Profile.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Profile', to: '/profile', active: true }
      ],
      pageTitle: 'Perfil'
    }
  },
  {
    path: '/agencies',
    name: 'agencies',
    component: () => import('@/views/Agencies/List.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Agencias'
    }
  },
  {
    path: '/agencies/create',
    name: 'agenciesCreate',
    component: () => import('@/views/Agencies/Create.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Crear registro Agencia',
      breadcrumb: [
        {
          text: 'Crear',
          active: true
        }
      ]
    }
  },
  {
    path: '/agencies/:uuid/detail',
    name: 'agenciesDetail',
    component: () => import('@/views/Agencies/Detail.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Detalle de Agencia',
      breadcrumb: [
        {
          text: 'Detalle',
          active: true
        }
      ]
    }
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/Projects/List.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Proyectos'
    }
  },
  {
    path: '/projects/create',
    name: 'projectsCreate',
    component: () => import('@/views/Projects/Create.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Crear registro Proyecto',
      breadcrumb: [
        {
          text: 'Crear',
          active: true
        }
      ]
    }
  },
  {
    path: '/projects/:uuid/detail',
    name: 'projectsDetail',
    component: () => import('@/views/Projects/Detail.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Detalle de Proyecto',
      breadcrumb: [
        {
          text: 'Detalle',
          active: true
        }
      ]
    }
  },
  {
    path: '/cranes',
    name: 'cranes',
    component: () => import('@/views/Cranes/List.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Gruas'
    }
  },
  {
    path: '/cranes/create',
    name: 'cranesCreate',
    component: () => import('@/views/Cranes/Create.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Crear registro Gruas',
      breadcrumb: [
        {
          text: 'Crear',
          active: true
        }
      ]
    }
  },
  {
    path: '/cranes/:uuid/detail',
    name: 'cranesDetail',
    component: () => import('@/views/Cranes/Detail.vue'),
    permissions: ['A', 'C', 'S'],
    meta: {
      pageTitle: 'Detalle de Grua',
      breadcrumb: [
        {
          text: 'Detalle',
          active: true
        }
      ]
    }
  },
  {
    path: '/teams',
    name: 'teams',
    component: () => import('@/views/Teams/List.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Cuadrillas',
      breadcrumb: [
        {
          text: 'Cuadrillas'
        }
      ]
    }
  },
  {
    path: '/teams/create',
    name: 'teamsCreate',
    component: () => import('@/views/Teams/Add.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Crear registro Cuadrilla',
      breadcrumb: [
        {
          text: 'Crear',
          active: true
        }
      ]
    }
  },
  {
    path: '/teams/:uuid/detail',
    name: 'teamsDetail',
    component: () => import('@/views/Teams/Detail.vue'),
    permissions: ['A', 'C', 'S'],
    meta: {
      pageTitle: 'Detalle de Cuadrilla',
      breadcrumb: [
        {
          text: 'Detalle',
          active: true
        }
      ]
    }
  },
  {
    path: '/teams/:uuid/members',
    name: 'teamsMembers',
    component: () => import('@/views/Teams/Members.vue'),
    permissions: ['A', 'C', 'S'],
    meta: {
      pageTitle: 'Miembros de Cuadrilla',
      breadcrumb: [
        {
          text: 'Cuadrillas',
          to: '/teams'
        },
        {
          text: 'Detalle',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[0].to = '/teams'
      next()
    }
  },
  {
    path: '/teams/:uuid/addmembers',
    name: 'teamsAddMembers',
    component: () => import('@/views/Teams/AddMembers.vue'),
    permissions: ['A', 'C'],
    meta: {
      pageTitle: 'Agregar Miembros a Cuadrilla',
      breadcrumb: [
        {
          text: 'Cuadrillas',
          to: '/teams'
        },
        {
          text: 'Miembros de Cuadrilla',
          to: '/',
          params: 'uuid'
        },
        {
          text: 'Agregar',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[0].to = '/teams'
      to.meta.breadcrumb[1].to = `/teams/${to.params.uuid}/members`
      next()
    }
  },
  {
    path: '/gabinets',
    name: 'gabinets',
    component: () => import('@/views/Gabinets/List.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Menú', to: '/menu' },
        { text: 'Lista', active: true }
      ],
      pageTitle: 'Gabinetes'
    }
  },
  {
    path: '/gabinets/create',
    name: 'gabinetsCreate',
    component: () => import('@/views/Gabinets/Create.vue'),
    permissions: ['A', 'C', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Lista', to: '/gabinets' },
        { text: 'Instalación', active: true }
      ],
      pageTitle: 'Instalación de Gabinetes'
    }
  },
  {
    path: '/gabinets/:uuid/detail',
    name: 'gabinetsDetail',
    component: () => import('@/views/Gabinets/Detail.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Lista', to: '/gabinets' },
        { text: 'Detalle', active: true }
      ],
      pageTitle: 'Detalle de Gabinete'
    }
  },
  {
    path: '/gabinets/:uuid/commands',
    name: 'gabinetsCommands',
    component: () => import('@/views/Gabinets/CommandsDetail.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        {
          text: 'Lista',
          to: '/gabinets'
        },
        {
          text: 'Detalle',
          to: '/',
          params: 'uuid'
        },
        {
          text: 'Detalle de Comandos',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[0].to = '/gabinets'
      to.meta.breadcrumb[1].to = `/gabinets/${to.params.uuid}/detail`
      next()
    }
  },
  {
    path: '/gabinets/:uuid/logs',
    name: 'gabinetsLogs',
    component: () => import('@/views/Gabinets/LogsDetail.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        {
          text: 'Lista',
          to: '/gabinets'
        },
        {
          text: 'Detalle',
          to: '/',
          params: 'uuid'
        },
        {
          text: 'Detalle de Logs',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[0].to = '/gabinets'
      to.meta.breadcrumb[1].to = `/gabinets/${to.params.uuid}/detail`
      next()
    }
  },
  {
    path: '/gabinets/:device/:uuid/sinamedLogs',
    name: 'sinamedLogs',
    component: () => import('@/views/Gabinets/LogsSinamed.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        {
          text: 'Lista',
          to: '/gabinets'
        },
        {
          text: 'Detalle',
          to: '/',
          params: 'device'
        },
        {
          text: 'Detalle de registros SiNaMed',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[0].to = '/gabinets'
      to.meta.breadcrumb[1].to = `/gabinets/${to.params.device}/detail`
      next()
    }
  },
  {
    path: '/meters/GWH0079',
    name: 'metersGWH0079',
    component: () => import('@/views/Meters/GWH0079/List.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Menú', to: '/menu' },
        { text: 'Lista', active: true }
      ],
      pageTitle: 'Medidores GWH00-79'
    }
  },
  {
    path: '/meters/GWH0079/:uuid/detail',
    name: 'metersGWH0079Detail',
    component: () => import('@/views/Meters/GWH0079/Detail.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Lista', to: '/meters/GWH0079' },
        { text: 'Detalle', active: true }
      ],
      pageTitle: 'Detalle de Medidor GWH00-79'
    }
  },
  {
    path: '/meters/GWH0079/install',
    name: 'metersGWH0079Create',
    component: () => import('@/views/Meters/GWH0079/Install.vue'),
    permissions: ['A', 'C', 'I', 'S'],
    meta: {
      breadcrumb: [
        { text: 'Lista', to: '/meters/GWH0079' },
        { text: 'Instalación', active: true }
      ],
      pageTitle: 'Instalación de Medidor GWH00-79'
    }
  },
  {
    path: '/meters/GWH0079/register',
    name: 'metersGWH0079NewCreate',
    component: () => import('@/views/Meters/GWH0079/Register.vue'),
    permissions: ['A', 'C', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Lista', to: '/meters/GWH0079' },
        { text: 'Instalación', active: true }
      ],
      pageTitle: 'Registro de Medidor GWH00-79'
    }
  },
  {
    path: '/meters/GWH0079/:uuid/logs',
    name: 'metersGWH0079Logs',
    component: () => import('@/views/Meters/GWH0079/LogsDetail.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        {
          text: 'Lista',
          to: '/meters/GWH0079'
        },
        {
          text: 'Detalle',
          to: '/',
          params: 'uuid'
        },
        {
          text: 'Detalle de Logs Medidor GWH00-79',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[0].to = '/meters/GWH0079'
      to.meta.breadcrumb[1].to = `/meters/GWH0079/${to.params.uuid}/detail`
      next()
    }
  },
  {
    path: '/meters',
    name: 'meters',
    component: () => import('@/views/Meters/List.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Menú', to: '/menu' },
        { text: 'Lista', active: true }
      ],
      pageTitle: 'Medidores'
    }
  },
  {
    path: '/meters/:uuid/detail',
    name: 'metersDetail',
    component: () => import('@/views/Meters/Detail.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Lista', to: '/meters' },
        { text: 'Detalle', active: true }
      ],
      pageTitle: 'Detalle de Medidor'
    }
  },
  {
    path: '/meters/:uuid/logs',
    name: 'metersLogs',
    component: () => import('@/views/Meters/LogsDetail.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        {
          text: 'Lista',
          to: '/meters'
        },
        {
          text: 'Detalle',
          to: '/',
          params: 'uuid'
        },
        {
          text: 'Detalle de Logs',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[0].to = '/meters'
      to.meta.breadcrumb[1].to = `/meters/${to.params.uuid}/detail`
      next()
    }
  },
  {
    path: '/meters/:uuid/sinamedLogs',
    name: 'sinamedLogs',
    component: () => import('@/views/Meters/LogsSinamed.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        {
          text: 'Lista',
          to: '/meters'
        },
        {
          text: 'Detalle',
          to: '/',
          params: 'uuid'
        },
        {
          text: 'Detalle de registros SiNaMed',
          active: true
        }
      ]
    },
    beforeEnter: (to, from, next) => {
      to.meta.breadcrumb[0].to = '/meters'
      to.meta.breadcrumb[1].to = `/meters/${to.params.uuid}/detail`
      next()
    }
  },
  {
    path: '/sinamed',
    name: 'sinamed',
    component: () => import('@/views/Meters/Sinamed.vue'),
    permissions: ['A', 'C'],
    meta: {
      breadcrumb: [
        { text: 'Lista', to: '/meters' },
        { text: 'SiNaMed', active: true }
      ],
      pageTitle: 'SiNaMed'
    }
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/Map.vue'),
    permissions: ['A', 'C', 'S'],
    meta: {
      pageTitle: 'Mapa'
    }
  },
  {
    path: '/map/GWH0079',
    name: 'mapGWH0079',
    component: () => import('@/views/MapGWH0079.vue'),
    permissions: ['A', 'C', 'S'],
    meta: {
      pageTitle: 'Mapa GWH00-79'
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard/Menu.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      pageTitle: 'Tablero'
    }
  },
  {
    path: '/dashboard/general',
    name: 'general',
    component: () => import('@/views/Dashboard/General.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Tablero', to: '/dashboard' },
        { text: 'General', active: true }
      ],
      pageTitle: 'Tablero General'
    }
  },
  {
    path: '/dashboard/deployment',
    name: 'deployment',
    component: () => import('@/views/Dashboard/Deployment.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Tablero', to: '/dashboard' },
        { text: 'Despliegue', active: true }
      ],
      pageTitle: 'Tablero de despliegue'
    }
  },
  {
    path: '/dashboard/GWH0079',
    name: 'dashboardGWH0079',
    component: () => import('@/views/Dashboard/GWH0079/Menu.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      pageTitle: 'Tablero GWH00-79'
    }
  },
  {
    path: '/dashboard/GWH0079/general',
    name: 'generalGWH0079',
    component: () => import('@/views/Dashboard/GWH0079/General.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Tablero GWH00-79', to: '/dashboard/GWH0079' },
        { text: 'General', active: true }
      ],
      pageTitle: 'Tablero General Medidores GWH00-79'
    }
  },
  {
    path: '/dashboard/GWH0079/deployment',
    name: 'deploymentGWH0079',
    component: () => import('@/views/Dashboard/GWH0079/Deployment.vue'),
    permissions: ['A', 'C', 'S', 'I'],
    meta: {
      breadcrumb: [
        { text: 'Tablero GWH00-79', to: '/dashboard/GWH0079' },
        { text: 'Despliegue', active: true }
      ],
      pageTitle: 'Tablero de despliegue Medidores GWH00-79'
    }
  }
]

const getMenu = async () => {
  const userRole = await appActiveUser().userRole.toString()
  const userMenu = []
  for (let index = 0; index < menu.length; index++) {
    if (menu[index].permissions.indexOf(userRole) >= 0) {
      userMenu.push(menu[index])
    }
  }
  router.addRoutes(userMenu)
}

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  const user = appActiveUser()
  if (!empty(user) && user.token.length > 0) {
    getMenu()
  }
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

router.beforeEach((to, from, next) => {
  const user = appActiveUser()
  if (empty(user) && to.path !== '/') {
    return next('/')
  }

  if (!empty(user) && to.path === '/') {
    return next('/menu')
  }
  messages().then(() => next())
})

router.beforeEach((to, from, next) => {
  const user = appActiveUser()
  if (typeof user === 'function') {
    logout()
    return next('/')
  }
  const pathUpdate = '/update-one-time-password'
  if (!empty(user) && !user.hasUpdatedPassword && to.path !== pathUpdate) {
    return next(pathUpdate)
  }
  if (!empty(user) && user.hasUpdatedPassword && to.path === pathUpdate) {
    return next('/menu')
  }
  next()
})

export default router
