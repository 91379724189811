export default {
  namespaced: true,
  state: {
    isExpired: false
  },
  getters: {
    current: state => {
      const { isExpired } = state
      return isExpired
    }
  },
  mutations: {
    SETEXPIRED (state) {
      state.isExpired = true
    },
    SETVALID (state) {
      state.isExpired = false
    }
  },
  actions: {}
}
